<template>
  <v-data-table
    v-bind="$attrs"
    must-sort
    :fixed-header="fixedHeader"
    :show-expand="showExpand"
    :loading="loading"
    :headers="tableHeaders"
    :items="lab_results"
    item-key="uuid"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 20, 50, 100, 200 ],
      itemsPerPageText: `Lab results ${ $t('per page') }:`,
      showFirstLastPage: true,
    }"
    :items-per-page="50"
    no-data-text="No lab results found"
    class="elevation-2"
  >
    <template
      v-if="showFilters"
      #top
    >
      <v-row
        class="pt-3 px-3 mb-n3"
        :dense="$vuetify.breakpoint.mobile"
      >
        <v-col
          xcols="12"
          xmd="3"
          xlg="2"
          cols="auto"
        >
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer style="width:10px" />
        <filter-button
          :filters.sync="options.filter"
          @clear="$set(options, 'filter', {})"
        />
        <v-col v-if="$auth.check({ clients: ['edit', 'view'] }) && !$auth.user().provider">
          <client-filter
            key="client-filter"
            :value.sync="options.filter.client"
            :params="{ has: 'labResults' }"
          />
        </v-col>
        <v-col v-if="$auth.check({ facilities: ['*', 'view', 'edit'] })">
          <facility-filter
            key="facility-filter"
            :value.sync="options.filter.facility"
            :params="{ has: 'labResults' }"
          />
        </v-col>
        <v-col v-if="!$auth.user().provider">
          <provider-filter
            key="provider-filter"
            :value.sync="options.filter.provider"
            :params="{ has: 'labResults' }"
          />
        </v-col>
        <v-col>
          <source-filter
            :value.sync="options.filter.source"
          />
        </v-col>
        <v-col>
          <organism-filter
            :value.sync="options.filter.organism"
            :params="{ has: 'labResults' }"
          />
        </v-col>
        <v-col v-if="!$auth.user().client && !$auth.user().provider">
          <organism-category-filter
            :value.sync="options.filter.organismCategory"
            :params="{ has: 'labResults' }"
          />
        </v-col>
        <v-col>
          <date-filter
            :value.sync="options.filter.created_at"
            :label="$t('received') | capitalize"
          />
        </v-col>
        <v-col>
          <table-filter
            :value.sync="options.filter.method"
            :choices="testing_methods"
            icon="fal fa-flask"
            :label="$t('Method')"
            multiple
          />
        </v-col>
        <v-col>
          <status-filter
            :value.sync="options.filter.status"
            :type="($auth.user().client && $auth.user().client.services) || $auth.user().provider ? 'one choice reports' : 'lab results'"
          />
        </v-col>
        <v-col
          v-if="
            $auth.check({'lab results':'add'}) &&
              (
                ($auth.user().client?.services.some(x => x.name.includes('OneChoice Reports'))) ||
                (!$auth.user().client)
              )
          "
          class="shrink"
        >
          <add-lab-result
            @lab-result-added="fetchLabResults"
          />
        </v-col>
      </v-row>
    </template>
    <template #header.allergies_count>
      <v-icon
        small
        title="Allergies Reported"
      >
        fal fa-allergies
      </v-icon>
    </template>
    <template #header.organisms_count>
      <v-icon
        small
        title="Organisms Detected"
      >
        fal fa-disease
      </v-icon>
    </template>
    <template #header.resistance_genes_count>
      <v-icon
        small
        title="Resistance Genes Detected"
      >
        fal fa-shield-virus
      </v-icon>
    </template>
    <template #item.client="{ item }">
      <client-icon
        :key="item.client.uuid"
        :name="item.client.name"
        :icon="item.client.icon"
      />
    </template>
    <template #item.created_at="{ item }">
      <span class="text-caption">
        {{ item.created_at | moment(dateFormat) }}
      </span>
    </template>
    <template #item.patient="{ item }">
      <span data-private>
        <patient-name
          :key="item.uuid"
          :patient="item.patient"
          first-initial
        />
        <v-tooltip
          v-if="item.patient_age_bracket && item.patient_age_bracket != 'adult'"
          :color="stringColor(item.patient_age_bracket, 1, 2.3)"
          top
          small
        >
          <template #activator="{ on }">
            <span v-on="on">
              <age-bracket-chip
                :key="item.patient_age_bracket"
                :bracket="item.patient_age_bracket"
                :class="['ml-1', 'mt-n1']"
                icon-only
              />
            </span>
          </template>
          {{ $t('x_patient', { x: $t(`age_brackets.${item.patient_age_bracket}`) }) | capitalize }}
        </v-tooltip>
        <v-tooltip
          v-if="item.pregnant"
          top
          small
        >
          <template #activator="{ on }">
            <v-icon
              small
              color="pink lighten-2"
              class="ml-1 mt-n1"
              v-on="on"
            >
              fas fa-person-pregnant
            </v-icon>
          </template>
          Pregnant Patient
        </v-tooltip>
      </span>
    </template>
    <template #item.provider="{ item }">
      <template v-if="item.provider">
        {{ item.provider.first_name.charAt(0) }}. {{ item.provider.last_name }}
        <v-icon
          v-if="item.created_at > item.provider.advanced_asp"
          key="advanced_asp_icon"
          color="cyan"
          size="14"
          title="Advanced ASP Provider"
        >
          fas fa-star
        </v-icon>
      </template>
      <v-icon
        v-else
        color="grey lighten-1"
      >
        fal fa-horizontal-rule
      </v-icon>
    </template>
    <template #item.facility="{ item }">
      <template v-if="item.facility">
        {{ item.facility.name | truncate(18) }}
      </template>
      <v-icon
        v-else
        color="grey lighten-1"
      >
        fal fa-horizontal-rule
      </v-icon>
    </template>
    <template #item.method="{ item }">
      <method-chip
        :key="item.method"
        :method="item.method"
      />
    </template>
    <template #item.sample_type="{ item }">
      <v-edit-dialog
        v-if="$auth.check({ 'sample types': 'edit' }) && item.sample_source != 'invalid'"
        :return-value.sync="item.sample_source"
        large
        @save="updateSource(item.sample_type, item.sample_source)"
      >
        <source-icon
          v-if="item.sample_type && sourceIcon"
          :key="item.sample_source"
          :name="item.sample_type"
          :source="item.sample_source"
        />
        <source-chip
          v-else-if="item.sample_type"
          :key="item.sample_type"
          :name="item.sample_type"
          :source="item.sample_source"
        />
        <template #input>
          <source-select
            :key="item.sample_type"
            v-model="item.sample_source"
            :label="item.sample_type"
            dense
            :multiple="false"
            menu-props="auto, offsetY"
            class="text-caption pt-4"
            item-value="value"
          />
        </template>
      </v-edit-dialog>
      <source-chip
        v-else-if="item.sample_type"
        :key="item.sample_type"
        :name="item.sample_type"
        :source="item.sample_source"
      />
    </template>
    <template #item.resistance_genes_count="{ item }">
      <template v-if="item.resistance_tested > 0">
        {{ item.resistance_genes_count }}
      </template>
      <v-icon
        v-else
        key="fal fa-hyphen"
        color="grey lighten-1"
      >
        fal fa-hyphen
      </v-icon>
    </template>
    <template #item.status="{ item }">
      <status-chip
        v-if="item.status"
        :key="item.status"
        :status="item.status"
        :reason="item.statuses?.[0].reason"
        :icon="statusIcon(item)"
        :badge="statusBadge(item)"
        :type="($auth.user().client && $auth.user().client.services) || $auth.user().provider ? 'one choice reports' : 'lab results'"
        block
      />
    </template>
    <template
      #item.actions="{ item }"
      class="text-right"
    >
      <lab-result-actions
        :lab-result="item"
        @fetch-lab-results="fetchLabResults"
        @edit-lab-result="editLabResult"
      />
    </template>
    <template #expanded-item="{ item }">
      <td :colspan="tableHeaders.length">
        <v-row class="my-0">
          <v-col
            xl="3"
            lg="4"
            md="5"
            sm="6"
          >
            <v-list-item
              two-line
              selectable
            >
              <v-list-item-content>
                <v-list-item-subtitle>UUID</v-list-item-subtitle>
                <v-list-item-title>
                  {{ item.uuid }}
                  <v-btn
                    small
                    icon
                    tile
                    color="info"
                    title="Copy"
                    @click="$clipboard(item.uuid)"
                  >
                    <v-icon small>
                      mdi-content-copy mdi-flip-h
                    </v-icon>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <activity-timeline
              v-if="item.activity"
              :item="item"
              class="ml-n5"
            />
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-list-item
                  v-if="item.provider"
                  selectable
                >
                  <v-list-item-icon class="mr-4">
                    <v-icon>
                      fal fa-fw fa-user-md
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-capitalize">
                      {{ $tc('provider', 1) }}
                    </v-list-item-subtitle>
                    <v-list-item-title>{{ item.provider.first_name }} {{ item.provider.last_name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item
                  v-if="item.facility"
                  selectable
                >
                  <v-list-item-icon class="mr-4">
                    <v-icon>
                      fal fa-fw fa-hospital
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-capitalize">
                      {{ $tc('facility', 1) }}
                    </v-list-item-subtitle>
                    <v-list-item-title v-text="item.facility.name" />
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col v-if="$auth.check({ patients: 'phi' })">
                <v-list-item selectable>
                  <v-list-item-icon class="mr-4">
                    <v-icon data-private>
                      fal fa-fw fa-{{ item.patient.gender }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ $t('Patient Name') }}</v-list-item-subtitle>
                    <v-list-item-title data-private>
                      {{ item.patient.first_name }} {{ item.patient.last_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item selectable>
                  <v-list-item-icon class="mr-4">
                    <v-icon>
                      fal fa-fw fa-birthday-cake
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ $t('dates.birth') }}</v-list-item-subtitle>
                    <v-list-item-title data-private>
                      {{ item.patient.dob | moment('L') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item
                  selectable
                >
                  <v-list-item-icon class="mr-4">
                    <v-icon>
                      fal fa-fw fa-vial
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ $t('dates.draw') }}</v-list-item-subtitle>
                    <v-list-item-title>{{ item.draw | moment('L LT') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item
                  selectable
                >
                  <v-list-item-icon class="mr-4">
                    <v-icon>
                      fal fa-fw fa-file-medical
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ $t('Lab Record') }} {{ $t('Created') }}</v-list-item-subtitle>
                    <v-list-item-title>{{ item.created | moment('L LT') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="item.interpretation"
            class="shrink"
          >
            <!-- <v-list-item>
              <v-file-input
                v-model="files[item.uuid]"
                counter
                dense
                :show-size="1000"
                :loading="loading[item.uuid]"
                label="Upload PDF"
                @change="uploadInterpretationPDF(item)"
              />
            </v-list-item> -->
          </v-col>
        </v-row>
      </td>
    </template>
  </v-data-table>
</template>
<script>
  import Vue2Filters from 'vue2-filters';
import { mapMutations } from 'vuex';

  export default {
    components: {
      AddLabResult: () => import('@/components/lab_result/AddLabResult.vue'),
      LabResultActions: () => import('@/components/tables/menus/LabResultActions.vue'),
      ClientFilter: () => import('@/components/tables/filters/ClientFilter.vue'),
      ClientIcon: () => import('@/components/ClientIcon.vue'),
      DateFilter: () => import('@/components/tables/filters/DateFilter.vue'),
      FacilityFilter: () => import('@/components/tables/filters/FacilityFilter.vue'),
      FilterButton: () => import('@/components/tables/filters/FilterButton.vue'),
      OrganismCategoryFilter: () => import('@/components/tables/filters/OrganismCategoryFilter.vue'),
      OrganismFilter: () => import('@/components/tables/filters/OrganismFilter.vue'),
      PatientName: () => import('@/components/patient/PatientName.vue'),
      ProviderFilter: () => import('@/components/tables/filters/ProviderFilter.vue'),
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      SourceChip: () => import('@/components/source/SourceChip.vue'),
      SourceFilter: () => import('@/components/tables/filters/SourceFilter.vue'),
      SourceIcon: () => import('@/components/source/SourceIcon.vue'),
      SourceSelect: () => import('@/components/source/SourceSelect.vue'),
      StatusChip: () => import('@/components/StatusChip.vue'),
      StatusFilter: () => import('@/components/tables/filters/StatusFilter.vue'),
      TableFilter: () => import('@/components/tables/filters/TableFilter.vue'),
      MethodChip: () => import('@/components/MethodChip.vue'),
      ActivityTimeline: () => import('@/components/lab_result/ActivityTimeline.vue'),
      AgeBracketChip: () => import('@/components/AgeBracketChip.vue'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      search: {
        type: String,
        default: null,
      },
      fixedHeader: {
        type: Boolean,
        default: true,
      },
      showFilters: {
        type: Boolean,
        default: true,
      },
      showExpand: {
        type: Boolean,
        default: true,
      },
      itemsPerPage: {
        type: Number,
        default: 15,
      },
      sortBy: {
        type: String,
        default: 'last_name',
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      dateFormat: {
        type: String,
        default: 'L LT',
      },
      sourceIcon: {
        type: Boolean,
        default: false,
      },
    },
    metaInfo: {
      title: 'Lab Results',
    },
    data () {
      return {
        pdf: null,
        files: {},
        loading: false,
        options: this.$store.state.filters.lab_results ? this.$store.state.filters.lab_results : {
          filter: {},
        },
        totalItems: null,
        lab_results: [],
        timer: null,
        time_counter: null,
        interval: 15 * 60,
        time_left: this.interval - 1,
      }
    },
    computed: {
      headers () {
        return [
          {
            text: this.$options.filters.capitalize(this.$tc('client', 1)),
            value: 'client',
            sortable: false,
          },
          {
            text: this.$t('Result ID'),
            value: 'external_id',
          },
          {
            text: this.$options.filters.capitalize(this.$t('received')),
            value: 'created_at',
          },
          {
            text: this.$t('Method'),
            value: 'method',
          },
          {
            text: this.$options.filters.capitalize(this.$tc('patient', 1)),
            value: 'patient',
            sortable: false,
          },
          //   {
          //     text: 'D.O.B.',
          //     value: 'patient.dob',
          //     sortable: false,
          //   },
          {
            text: this.$options.filters.capitalize(this.$tc('provider', 1)),
            value: 'provider',
            sortable: false,
          },
          {
            text: this.$options.filters.capitalize(this.$tc('facility', 1)),
            value: 'facility',
            sortable: false,
          },
          {
            text: this.$options.filters.capitalize(this.$t('source')),
            value: 'sample_type',
            sortable: false,
          },
          // {
          //   text: 'Diagnosis',
          //   value: 'diagnosis'
          // },
          {
            text: this.$options.filters.capitalize(this.$tc('allergy', 1)),
            value: 'allergies_count',
            align: 'center',
          },
          {
            text: this.$options.filters.capitalize(this.$tc('organism', 2)),
            value: 'organisms_count',
            align: 'center',
          },
          {
            text: this.$options.filters.capitalize(this.$tc('resistance', 2)),
            value: 'resistance_genes_count',
            align: 'center',
          },
          {
            text: this.$options.filters.capitalize(this.$t('status')),
            value: 'status',
            sortable: false,
          },
          {
            sortable: false,
            align: 'right',
            value: 'actions',
          },
        ]
      },
      tableHeaders () {
        var headers = this.$attrs.headers ? this.$attrs.headers : this.headers
        if (!this.$auth.check({ clients: ['view','edit','*'] })) {
          headers = headers.filter(x => x.value && !['client','organisms_count','resistance_genes_count','allergies_count'].includes(x.value))
        }
        if (this.$auth.user().provider) {
          headers.find(x => x.value == 'client').text = this.$options.filters.capitalize(this.$t('lab'))
          headers = headers.filter(x => x.value && !['provider'].includes(x.value))
        }
        return headers
      },
      testing_methods () {
        return this.$testing_methods
      },
    },
    watch: {
      '$route.params.filter': {
        deep: true,
        handler () {
          this.options.filter = this.$route.params.filter
        },
      },
      options: {
        deep: true,
        handler (options) {
          this.setFilterLabResults(options)
          this.fetchLabResults()
        },
      },
      immediate: true,
    },
    beforeDestroy () {
      clearInterval(this.timer)
    },
    mounted () {
      if (this.$route.query.length > 0) {
        this.options.search = this.$route.query.search
        this.options.filter = this.$route.query
      }
      this.options.filter = {
        ...this.options.filter,
        ...this.$route.query,
        ...this.params,
      }
    },
    methods: {
      ...mapMutations('filters', ['setFilterLabResults']),
      rowClick () {
        // this.$router.push({ to: item.uuid })
      },
      fetchLabResults () {
        this.loading = true
        const promise = this.axios.get('lab_results', {
          params:
            {
              ...{
                page: this.options.page,
                count: this.options.itemsPerPage,
                sort: this.options.sortBy[0],
                order: this.options.sortDesc[0] ? 'desc' : 'asc',
                search: this.options.search,
              },
              ...this.options.filter,
            },
        })

        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchLabResults()
            return
          }
          this.lab_results = response.data.data
          this.totalItems = response.data.total
          this.$set(this.options, 'page', response.data.current_page)
          this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
        }).catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        }).finally(() => {
          this.loading = false
        })
      },
      editLabResult (uuid) {
        this.$router.push({ name: 'Edit Lab Result', params: { uuid: uuid } })
      },
      uploadInterpretationPDF (item) {
        const formData = new FormData()
        formData.append('interpretation', this.files[item.uuid], this.files[item.uuid].name)
        formData.append('_method', 'patch')
        this.axios
          .post('lab_results/' + item.uuid + '/interpretation', formData)
          // .then(response => {
          //   const index = this.lab_results.indexOf(item)
          //   this.$delete(this.lab_results, index)
          //   this.options.totalItems--
          // })
      },
      updateSource (type, source) {
        this.axios.patch('admin/sources', {
          sample_type: type,
          sample_source: source,
        })
      },
      statusIcon (lab_result) {
        if (this.$auth.check({'lab results': ['*', 'interpret']}) && lab_result.record_lock && lab_result.record_lock.user.uuid != this.$auth.user().uuid) {
            return 'fal fa-lock-keyhole'
        }
        if (!this.$auth.user().client && !this.$auth.user().provider && (lab_result?.statuses?.[0].reason == 'auto' || lab_result?.interpretation?.approver_id === 0)) {
            return 'fal fa-robot'
        }
        return null
      },
      statusBadge (lab_result) {
        if (lab_result.status == 'pending approval' && lab_result.interpretation && !lab_result.interpretation.approved_at) {
          return lab_result.interpretation.approval_count
        } else {
          return null
        }
      },
      startTimer () {
        clearInterval(this.timer)
        this.timer = setInterval(this.fetchLabResults, this.interval * 1000)

        clearInterval(this.time_counter)
        var counter = this.interval - 1
        this.time_counter = setInterval(function() {
          if (counter < 1) {
            counter = this.interval - 1
          }
          this.time_left = counter--
        }.bind(this), 1000)
      },
    },
    beforeRouteLeave (to, from, next) {
      clearInterval(this.timer)
      next()
    },
  }
</script>
